<template>
    <div class="nsi-item">

        <PageLoader v-if="!pageLoad" />

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Создание запроса</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="danger"
                    v-b-modal.confirm-delete
                >Удалить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="success"
                    @click="createNsi(true)">Сохранить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    @click="createNsi()"
                >Отправить на согласование
                </BButton>
            </div>

        </div>

        <div class="mb-4">

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Тип
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.type"
                        :state="$v.sendData.$error ? $v.sendData.type.required : null"
                        :options="typeOptions"
                    />
                </div>
            </div>


            <div class="d-flex table-bordered mb-1"
                v-if="isProvider"
            >
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >№ договора поставки
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.provider_contract_id"
                        :state="$v.sendData.$error ? $v.sendData.provider_contract_id.required : null"
                        :options="providersContractsOptions.slice(1)"
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1"
                v-if="isContractor"
            >
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >№ договора подряда
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.work_agreement_id"
                        :state="$v.sendData.$error ? $v.sendData.work_agreement_id.required : null"
                        :options="workAgreementsOptions.slice(1)"
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Филиал
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <!-- <b-form-select
                        v-model="sendData.organization_id"
                        :state="$v.sendData.$error ? $v.sendData.organization_id.required : null"
                        :options="organizations"
                    /> -->
                    <v-select
                        label="text"
                        v-bind:class="{ 'v-select-error': $v.sendData.organization_id.$error }"
                        v-bind:appendToBody="true"
                        v-bind:options="organizationsOptions.slice(1)"
                        v-bind:placeholder="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ) ? organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ).text : ''"
                        v-bind:value="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id )"
                        v-on:input="value => ( sendData.organization_id = value.value )"
                        @search="getOrganizationsOptionsByQuery"
                    >
                        <template #no-options>
                        Нет результатов.
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Номенклатура
                </div>
                <div
                    class="px-2 py-1 flex-grow-1"
                >

                    <b-form-input
                        v-if="sendData.type === 'new'"
                        v-model="sendData.nomenclature_name"
                        :state="$v.sendData.$error ? $v.sendData.nomenclature_name.required : null"
                        trim
                    />

                    <div
                        :class="{'v-select-error': $v.sendData.$error ? !$v.sendData.nomenclature_id.required : null}"
                        v-else
                    >
                        <v-select
                            label="name"
                            v-model="sendData.nomenclature_id"
                            :options="nomenclatureOptions"
                            :appendToBody="true"
                            :reduce="item => item.id"
                            @search="getNomenclatureOptionsByQuery"
                        >
                            <template #no-options>
                                Нет результатов.
                            </template>
                        </v-select>
                    </div>

                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Описание
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-textarea
                        v-model="sendData.description"
                        :state="$v.sendData.$error ? $v.sendData.description.required : null"
                        trim
                    />
                </div>
            </div>

            <div
                v-if="sendData.type === 'new'"
                class="d-flex table-bordered mb-1"
            >
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Единица измерения
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-input
                        v-model="sendData.nomenclature_unit"
                        :state="$v.sendData.$error ? $v.sendData.nomenclature_unit.required : null"
                        trim
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Ответственный
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-input
                        v-model="sendData.responsible_full_name"
                        :state="$v.sendData.$error ? $v.sendData.responsible_full_name.required : null"
                        trim
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Комментарий
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-textarea
                        v-model.trim="sendData.contr_agent_comment"
                        :state="$v.sendData.$error ? $v.sendData.contr_agent_comment.required : null"
                        placeholder="Текст комментария"
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Приложение
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-file
                        v-model="sendData.file"
                        placeholder="Выберите файл"
                        plain
                    />
                    <div
                        v-if="fileUrl"
                        class="mt-2"
                    >
                        Ранее загруженный файл:
                        <DownloadLink
                            :id="id"
                            from="request-addition/nomenclature"
                            :url="fileUrl"
                        />
                        <div class="mt-1">
                            <a
                                href="#"
                                class="text-danger"
                                @click="clearFile"
                            >
                                <b-icon-trash/>
                                Удалить
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <b-modal
            id="confirm-delete"
            title="Удаление запроса на добавление НСИ"
            centered
        >
            <p class="m-0">Вы действительно хотите удалить запрос на добавление НСИ?</p>
            <template #modal-footer="{ hide }">
                <b-button @click="hide()">Нет</b-button>
                <b-button
                    variant="danger"
                    @click="deleteNsi"
                >Да
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>

import {mapState, mapGetters} from 'vuex'
import {required} from "vuelidate/lib/validators"
import {debounce} from "debounce"
import PageLoader from "@/components/UI/PageLoader"
import DownloadLink from "@/components/UI/DownloadLink"
import axios from 'axios'

export default {
    name: "NsiForm",
    props: {
        edit: Boolean
    },
    components: {
        PageLoader,
        DownloadLink
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        ...mapState({
            currentNsi: state => state.nsi.currentNsi
        }),
        ...mapGetters({
            providersContractsOptions: 'getProvidersContractsOptions',
            organizationsOptions: 'getOrganizationsOptions',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            isProvider: 'isProvider',
            isContractor: 'isContractor'
        })
    },
    validations() {
        const provider_contract_id = this.isProvider ? {required} : {}
        const work_agreement_id = this.isContractor ? {required} : {}

        if (this.sendData.type === 'new') {
            return {
                sendData: {
                    type: {required},
                    provider_contract_id,
                    work_agreement_id,
                    organization_id: {required},
                    responsible_full_name: {required},
                    description: {required},
                    contr_agent_comment: {required},
                    nomenclature_name: {required},
                    nomenclature_unit: {required},
                }
            }
        } else {
            return {
                sendData: {
                    type: {required},
                    provider_contract_id,
                    work_agreement_id,
                    organization_id: {required},
                    nomenclature_id: {required},
                    responsible_full_name: {required},
                    description: {required},
                    contr_agent_comment: {required}
                }
            }
        }
    },
    watch: {
        'sendData.provider_contract_id'() {
            this.getOrganizations()
        },
        'sendData.file'() {
            this.fileUrl = null
        }
    },
    data: () => ({
        sendData: {
            action: 'draft',
            type: 'new',
            provider_contract_id: null,
            work_agreement_id: null,
            organization_id: null,
            nomenclature_id: null,
            nomenclature_name: null,
            nomenclature_unit: null,
            responsible_full_name: null,
            description: null,
            contr_agent_comment: null,
            file: null
        },
        organizations: [],
        nomenclatureOptions: [],
        typeOptions: [
            {
                text: 'Новый',
                value: 'new'
            },
            {
                text: 'Изменить',
                value: 'change'
            }
        ],
        pageLoad: false,
        fileUrl: null
    }),
    methods: {
        deleteNsi() {
            if (this.edit) {
                this.$store.dispatch('deleteNsi', this.id)
                    .then(() => {
                        this.$bvToast.toast('Запрос на добавление НСИ успешно удален', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'success'
                        })
                        this.$router.push('/nsi-list')
                    })
                    .catch(() => {
                        this.$bvToast.toast('Что-то пошло не так, попробуйте позднее', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'danger'
                        })
                    })
            } else {
                this.$router.push('/nsi-list')
            }
        },
        createNsi(save = false) {
            if (this.$v.$invalid) {
                this.$v.$touch()
                this.$bvToast.toast('Заполните обязательные поля', {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
                return
            }
            this.sendData.action = save ? 'draft' : 'approve'

            if (this.edit) {
                this.$store.dispatch('updateNsi', {
                    id: this.id,
                    data: this.sendData
                })
                    .then(() => {
                        this.sendSuccess('Успешно')
                    })
                    .catch(err => {
                        this.sendError(err)
                    })
            } else {
                this.$store.dispatch('createNsi', this.sendData)
                    .then(() => {
                        this.sendSuccess('Успешно')
                    })
                    .catch(err => {
                        this.sendError(err)
                    })
            }
        },
        async getOrganizations() {
            await this.$store.dispatch('getNsiOrganizations', this.sendData.provider_contract_id).then(resp => {
                this.organizations = resp.map(organization => ({
                    text: organization.name,
                    value: organization.id
                }))
            })
        },
        getOrganizationsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getOrganizationsOptionsSearch(search, loading, this);
        }
        },
        getOrganizationsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/organizations', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'organizations',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),

        getNomenclatureOptionsByQuery(search, loading) {
            if (search.length > 1) {
                loading(true)
                this.getNomenclatureOptionsSearch(search, loading, this)
            }
        },
        getNomenclatureOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        vm.$store
            .dispatch("getNomenclatureSearch", search)
            .then((resp) => {
            this.nomenclatureOptions = resp;
            })
            .then(() => loading(false))
            .catch(() => loading(false));
        },
        800),
        sendSuccess(successText) {
            this.$bvToast.toast(successText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'success'
            })
            this.$router.push('/nsi-list')
        },
        sendError(err) {
            const errText = err.response.status === 422 ? 'Проверьте правильность заполненных полей' : 'Ошибка сервера. Попробуйте позднее.'
            this.$bvToast.toast(errText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'danger'
            })
        },
        clearFile() {
            this.fileUrl = null
            this.sendData.file = null
        }
    },
    async mounted() {
        if (this.edit) {
            await this.$store.dispatch('getCurrentNsi', this.id).catch(err => {
                if (err.response.status === 404) {
                    this.$router.push('/nsi-list')
                }
            })
            for (let key in this.sendData) {
                if (this.currentNsi[key]) {
                    this.sendData[key] = this.currentNsi[key]
                }
            }
            this.fileUrl = this.currentNsi.file_url
            if (this.currentNsi.nomenclature) {
                this.nomenclatureOptions.push(this.currentNsi.nomenclature)
            }
        }
        this.pageLoad = true
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
